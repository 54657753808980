.chatbox {
    /* ... rest of your styles ... */
    transition: all 0.3s ease-out;
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .chatbox.visible {
    opacity: 1;
    transform: translateY(0);
  }