.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rocketAnimation {
  0% { transform: translate(0, 0) rotate(0deg) scale(1); }
  50% { transform: translate(50vw, -25vh) rotate(15deg) scale(0.25); }
  100% { transform: translate(100vw, 100vh) rotate(30deg) scale(0.10); }
}

.animate-rocket {
  position: relative;
  animation: rocketAnimation 2s cubic-bezier(.17,.67,.83,.67) forwards;
  z-index: 1;
}

.animate-rocket::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px; /* Increase the width of the trail */
  height: 100%;
  background: linear-gradient(to bottom, yellow, transparent);
  animation: trail 2s linear forwards;
  z-index: -1; /* Place the trail behind the rocket image */
}

@keyframes trail {
  0% { height: 100%; top: 50%; }
  100% { height: 0; top: 100%; }
}

@font-face {
  font-family: 'Nasa';
  src: url('./font/nasa.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Nasa', sans-serif;
}

@keyframes dim {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

@keyframes shoot {
  0% { transform: translate(0, 0); opacity: 1; }
  100% { transform: translate(100vw, 100vh); opacity: 0; }
}

.star {
  animation: dim 2s infinite;
}

.shooting-star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  animation: shoot 2s linear infinite;
}

@keyframes swoopIn {
  0% {
    transform: scale(0) translateX(-100%);
    opacity: 0;
  }
  80% {
    transform: scale(0.9) translateX(10px);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
}

.swoop-in {
  animation: swoopIn 1s ease-out;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.card {
  animation: swoopIn 1s ease-out;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.animate-fade-out {
  animation: fade-out 1.25s;
}